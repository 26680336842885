






















































































import TheHeader from '@/components/Common/TheHeader.vue'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import Network from '@/components/settings/Network.vue'
import Shows from '@/components/settings/Shows.vue'
import PlansV2 from '@/components/Payment/PlansV2.vue'
import BillingUsage from '@/components/Payment/BillingUsage.vue'
import YoutubeBilling from '@/components/settings/YoutubeBilling.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import get from 'lodash.get'
import Subscription from '@/components/Payment/Subscription.vue'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    Network,
    Shows,
    YoutubeBilling,
    PlansV2,
    BillingUsage,
    Subscription,
  },
})
export default class ViewSettings extends Vue {
  @Getter networkId!: any
  @Getter networkType!: any
  @Getter currentNetwork!: any
  @Getter isYoutubeNew!: boolean
  @Getter isYoutubeOld!: boolean
  @Getter isVideo!: boolean

  selectedTab = 'PLANS'
  selectedRssFeedUid = ''
  route = ''

  get list() {
    const list = []

    if (this.isNetworkOwner || this.isNetworkBillingAllowed || this.isShowRole) {
      list.push({ title: 'Show settings', value: 'SHOW', param: 'shows' })
    }

    this.isYoutubeNew &&
      this.$permissions.isManageNetworkBillingAllowed() &&
      list.push({ title: 'Billing', value: 'BILLING', param: 'billing' })
    ;(this.isNetworkOwner || this.$permissions.isManageNetworkBillingAllowed()) &&
      list.push({ title: 'Default settings', value: 'DEFAULT', param: 'default' })
    this.isVideo &&
      list.push(
        { title: 'Plans', value: 'PLANS', param: 'plans' },
        { title: 'Usage', value: 'USAGE', param: 'usage' }
        // { title: 'Subscription', value: 'SUBSCRIPTION', param: 'subscription' }
      )

    list.push({ title: 'Network settings', value: 'NETWORK', param: 'network' })

    return list
  }

  get tabList() {
    return this.isYoutubeNew || this.isYoutubeOld
      ? this.list
      : this.isVideo
      ? this.list.filter((obj: any) => !['Billing', 'Show settings', 'Default settings'].includes(obj.title))
      : this.list.filter((obj: any) => obj.title !== 'Billing')
  }

  get isNetworkOwner() {
    return this.currentNetwork?.role?.name === 'Network Owner'
  }

  get isShowRole() {
    return this.currentNetwork?.role?.name.includes('Show')
  }

  get isNetworkBillingAllowed() {
    return this.$permissions.isManageNetworkBillingAllowed()
  }

  selectTab(tab: string) {
    this.$router.push(`/settings/${tab}`)
  }

  created() {
    this.$watch(() => this.$route, this.handleTab)
    this.handleTab()
    this.isShowRole && this.$router.push('/settings/shows')
  }

  mounted() {
    this.route = get(this.$router.currentRoute.query, 'card_added') !== undefined ? '/' : ''
  }

  handleTab() {
    const tab = this.$route.params.tab
    switch (tab) {
      case 'network':
        this.selectedTab = 'NETWORK'
        break
      case 'shows':
        this.selectedTab = 'SHOW'
        break
      case 'billing':
        this.selectedTab = 'BILLING'
        break
      case 'plans':
        this.selectedTab = 'PLANS'
        break
      case 'subscription':
        this.selectedTab = 'SUBSCRIPTION'
        break
      case 'usage':
        this.selectedTab = 'USAGE'
        break
    }
  }
}
